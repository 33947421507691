var CompassAnimate = (function() {
	var __private = {
		animate(ID) {
			var arrow = document.getElementById(ID);
			var top = 0;
			var deg = 0;

			if(arrow instanceof HTMLElement) top = arrow.getBoundingClientRect().top;
			
			document.addEventListener("scroll", function() {

				if(arrow instanceof HTMLElement) {
					deg = getDeg(deg);

					arrow.style.transform = `rotate3d(0, 0, 1, ${deg}deg)`;
				}
				
			}, true);


			function getDeg(oldDeg) {
				var newDeg = oldDeg || 0;
				var step   = 5;

				if(top > arrow.getBoundingClientRect().top) newDeg -= step;
				else if(top < arrow.getBoundingClientRect().top) newDeg += step;

				top = arrow.getBoundingClientRect().top;

				return newDeg;
			}
		}
	};

	return {
		run(ID) {
			__private.animate(ID);
		}
	};
})();