var AnchorLink = (function() {
	var __private = {
		listener(linkID) {
			var link = document.getElementById(linkID);
			

			if(link instanceof HTMLAnchorElement) {
				link.addEventListener("click", function(event) {
					event = event || window.event;
					event.preventDefault();

					var anchorSelector = this.getAttribute("href");
					var section = document.querySelector(anchorSelector);

					if(section instanceof HTMLElement) scrolling(anchorSelector.split("#")[1]);
					else window.location.href = `/${anchorSelector}`;
				})
			}
		}
	};

	return {
		init(linkID) {
			__private.listener(linkID);
		}
	};
})();