var Slider = (function() {
	var __private = {
		init(sliderSelector, options = {}) {
			var slider = document.querySelector(sliderSelector);
			var newSwiper;

			if(slider instanceof HTMLElement) newSwiper = new Swiper(slider, options);
		}
	};

	return {
		init(sliderSelector, options) {
			__private.init(sliderSelector, options);
		}
	};
})();