var Persons = (function() {
	var __private = {
		block     : document.getElementById("personsV1"),
		slider    : document.getElementById("personsV2"),
		slSelector: "#personSlider .swiper-container",
		pSwiper   : null,
		noneClass : "d_none",
		closeClass: "close",
		media     : 768,
		dataset   : {
			index : "index"
		},

		init() {
			if(this.block instanceof HTMLElement && this.slider instanceof HTMLElement && window.innerWidth < __private.media) {

			}
		},

		toSlider() {
			if(this.block instanceof HTMLElement && this.slider instanceof HTMLElement) {
				this.block.addEventListener("click", function(event) {
					event = event || window.event;
					var target = event.target;

					if(target instanceof HTMLElement && target.dataset[__private.dataset.index]) {
						this.classList.add(__private.noneClass);
						__private.slider.classList.remove(__private.noneClass);

						setTimeout(() => {
							__private.sliderInit(+target.dataset[__private.dataset.index]);
						}, 0);
					}
				})
			}
		},

		toBlock() {
			if(this.block instanceof HTMLElement && this.slider instanceof HTMLElement) {
				this.slider.addEventListener("click", function(event) {
					event = event || window.event;
					var target = event.target;

					if(target instanceof HTMLElement && target.classList.contains(__private.closeClass)) {
						this.classList.add(__private.noneClass);
						__private.block.classList.remove(__private.noneClass);

						setTimeout(() => {
							__private.sliderDestroy();
						}, 0);
					}
				})
			}
		},

		sliderInit(index) {
			if(!index || isNaN(index)) index = 0;

			__private.pSwiper = new Swiper(__private.slSelector, {
				slidesPerView: 1,
				spaceBetween: 0,
				initialSlide: index,
				autoHeight: !(navigator.userAgent.search(/Safari/) > 0 && navigator.userAgent.search(/Chrome/) < 0), // not for Safari

				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev"
				},

				on: {
					// fix autoHeight for Safari
					init() {
						if(navigator.userAgent.search(/Safari/) > 0 && navigator.userAgent.search(/Chrome/) < 0) {
							setTimeout(() => {
								document.querySelector(__private.slSelector).classList.add("swiper-container-autoheight");
								this.params.autoHeight = true;
								this.updateAutoHeight(300);
							}, 1000);
						}
					},
					slideChange() {
						if(navigator.userAgent.search(/Safari/) > 0 && navigator.userAgent.search(/Chrome/) < 0) {
							document.querySelector(__private.slSelector).classList.add("swiper-container-autoheight");
							this.params.autoHeight = true;
							this.updateAutoHeight(300);
						}
					}
				}
			});
		},

		sliderDestroy() {
			__private.pSwiper.destroy();
		}
	};

	return {
		run() {
			if(window.innerWidth > __private.media) {
				__private.toSlider();
				__private.toBlock();
			} else {
				if(__private.block instanceof HTMLElement)
					__private.block.classList.add(__private.noneClass);

				if(__private.slider instanceof HTMLElement) {
					__private.slider.classList.remove(__private.noneClass);

					setTimeout(() => {
						__private.sliderInit(0);
					}, 0);
				}
			}
		}
	};
})();