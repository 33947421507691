var MenuControl = (function() {
	var __private = {
		openClass: "open",
		stopScrollClass: "no-scroll",
		media: 575,
		dataset: {
			menuId: "menuId"
		},

		listener(selector) {
			var button = document.querySelector(selector);

			if(button instanceof HTMLElement && button.dataset[this.dataset.menuId] !== undefined) {
				button.addEventListener("click", function() {
					var menu = document.getElementById(this.dataset[__private.dataset.menuId]);

					if(menu instanceof HTMLElement) {
						this.classList.toggle(__private.openClass);
						menu.classList.toggle(__private.openClass);
		
						if(window.innerWidth <= __private.media)
							document.getElementsByTagName("html")[0].classList.toggle(__private.stopScrollClass);
					}
				});
			}
		}
	};

	return {
		init(selector) {
			__private.listener(selector);
		}
	};
})();