function Sticky() {
	var header = document.querySelector("header.header");
	var step   = -10;
	var stickyClass = "sticky";

	document.addEventListener("scroll", function() {

		if(header.nextElementSibling.getBoundingClientRect().top < step)
			header.classList.add(stickyClass);
		else
			header.classList.remove(stickyClass);

	}, true);
};