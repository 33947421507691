var ToSection = (function() {
	var __private = {
		dataset: {
			anchorID: "anchor",
		},

		listener(controlID) {
			var control = document.getElementById(controlID);

			if(control instanceof HTMLElement) {
				control.addEventListener("click", function(event) {
					event = event || window.event;
					event.preventDefault();

					var sectionID = this.dataset[__private.dataset.anchorID];
					
					if(sectionID !== undefined) scrolling(sectionID);
				})
			}
		}
	};

	return {
		init(controlID) {
			__private.listener(controlID);
		}
	};
})();