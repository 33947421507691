(function() {
	"use strict";

	document.addEventListener("DOMContentLoaded", function() {

		// sticky header
		Sticky();

		// Hamburger menu in header
		MenuControl.init(".header__button");

		// arrow to bottom
		ToSection.init("linkToBottom");

		// button `Talk to us` in header
		AnchorLink.init("toTalkButton");

		// Home page, animation for compass
		CompassAnimate.run("compassArr");

		// Home page, services section
		let tabs = document.getElementById("servicesTabs");
		let sSlider = document.getElementById("servicesSlider");
		if(window.innerWidth < 768) {
			if(tabs) tabs.style.display = "none";
			Slider.init("#servicesSlider .swiper-container", {
				slidesPerView: 1,
				spaceBetween: 0,
				autoHeight: true,
				pagination: {
					el: "#servicesSlider .swiper-pagination",
					type: "bullets",
					clickable: true
				},
			});
		} else {
			if(sSlider) sSlider.style.display = "none";
			Tabs.init("servicesTabs");
		}

		// About page, switch slider and block with persons
		Persons.run();

		// SLIDERS
		// testimonials slider
		Slider.init("#sliderTestimonials .swiper-container", {
			slidesPerView: 2,
			spaceBetween: 80,
			autoHeight: !(navigator.userAgent.search(/Safari/) > 0 && navigator.userAgent.search(/Chrome/) < 0), // not for Safari
			navigation: {
				nextEl: "#sliderTestimonials .swiper-container .swiper-button-next",
				prevEl: "#sliderTestimonials .swiper-container .swiper-button-prev"
			},
			pagination: {
				el: "#sliderTestimonials .swiper-container .swiper-pagination",
				type: "bullets",
				clickable: true
			},

			breakpoints: {
				768: {
					slidesPerView: 1,
					spaceBetween: 0
				}
			},

			on: {
				// fix autoHeight for Safari
				init() {
					if(navigator.userAgent.search(/Safari/) > 0 && navigator.userAgent.search(/Chrome/) < 0) {
						setTimeout(() => {
							document.querySelector("#sliderTestimonials .swiper-container").classList.add("swiper-container-autoheight");
							this.params.autoHeight = true;
							this.updateAutoHeight(300);
						}, 1000);
					}
				},
				slideChange() {
					if(navigator.userAgent.search(/Safari/) > 0 && navigator.userAgent.search(/Chrome/) < 0) {
						document.querySelector("#sliderTestimonials .swiper-container").classList.add("swiper-container-autoheight");
						this.params.autoHeight = true;
						this.updateAutoHeight(300);
					}
				}
			}
		});

		// Share social buttons
		let shareButtons = document.getElementsByClassName("share-buttons");
		for (let i = 0; i < shareButtons.length; i++) { ShareSocButtons.init(shareButtons[i]); }

		
	})
})();