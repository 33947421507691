var ShareSocButtons = (function() {
	var __private = {
		btnClass  : "share-button",
		moreClass : "share-button__more",
		hiddeClass: "share-button__hidde",
		dataset   : {
			show  : "show"
		},

		listener(container) {
			container.addEventListener("click", function(event) {
				event = event || window.event;
				var target  = event.target;
				
				var more  = container.querySelector(`.${__private.moreClass}`);
				var hidde = container.querySelector(`.${__private.hiddeClass}`);

				if(target instanceof HTMLElement) {
					let targetMore  = SearchParent.byClassName(target, __private.moreClass);
					let targetHidde = SearchParent.byClassName(target, __private.hiddeClass);

					if(targetMore  === more)  {
						__private.switch(container, "0", "1");
						more.classList.add("d_none");
					}

					if(targetHidde === hidde) {
						__private.switch(container, "1", "0");
						more.classList.remove("d_none");
					}
				}
			});
		},

		switch(container, i1, i2) {
			var buttons = container.querySelectorAll(`.${__private.btnClass}[data-${__private.dataset.show}='${i1}']`);

			for (let i = 0; i < buttons.length; i++) {
				const element = buttons[i];
				
				element.dataset[this.dataset.show] = i2;
			}
		}
	};

	return {
		init(container) {
			if(container instanceof HTMLElement) __private.listener(container);
		}
	};
})();