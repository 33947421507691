var Tabs = (function() {
	var __private = {
		role: {
			ul: "tablist",
			li: "presentation",
			link: "tab",
			section: "tabpanel"
		},

		aria: {
			sectionID: "controls",
			activeTab: "selected"
		},

		activeClass: "is-active",
		noneClass  : "d_none",

		listenter(containerID) {
			var tabs = document.getElementById(containerID);

			if(tabs instanceof HTMLElement) {
				tabs.addEventListener("click", function(event) {
					event = event || window.event;

					var target   = event.target;
					var links    = this.querySelectorAll(`*[role="${__private.role.link}"]`);
					var sections = this.querySelectorAll(`*[role="${__private.role.section}"]`);

					if(target instanceof HTMLElement && target.getAttribute("role") === __private.role.link) {
						event.preventDefault();

						let sectionID = target.getAttribute(`aria-${__private.aria.sectionID}`).split("#")[1];

						// switch tab
						for (let i = 0; i < links.length; i++) {
							const tab = links[i];
							
							if(tab !== target) {
								tab.parentElement.classList.remove(__private.activeClass);
								tab.removeAttribute(`aria-${__private.aria.activeTab}`);
							} else {
								tab.parentElement.classList.add(__private.activeClass);
								tab.setAttribute(`aria-${__private.aria.activeTab}`, "true");
							}
						}

						// show / hidde section
						for (let i = 0; i < sections.length; i++) {
							const sec = sections[i];

							if(sec instanceof HTMLElement) {
								if(sec.id === sectionID) sec.classList.remove(__private.noneClass);
								else sec.classList.add(__private.noneClass);
							}
						}
					}
				})
			}
		}
	};

	return {
		init(containerID) {
			__private.listenter(containerID);
		}
	};
})();